.grid {
  display: grid;
  grid-template-columns: repeat(11, minmax(1rem, 1fr));
  grid-auto-rows: 1fr;

  &:before {
    content: "";
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
}

.cell {
  border: 1px solid black;
  background-color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.interactiveCell {
  @extend .cell;
  cursor: pointer;
  &:hover {
    background-color: whitesmoke;
  }
}

.cellContent {
  cursor: default;
  font-size: 1.3rem;
}

.hit {
  @extend .cellContent;
  color: black;
}

.miss {
  @extend .cellContent;
  color: crimson;
}
