@import "../../variables";

.header {
  height: $header_height;
  background-color: black;
  color: $main;
  display: flex;
  justify-content: center;
  align-items: center;
}
