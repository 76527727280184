.playArea {
  display: grid;
  grid-template-columns: 33% 33% 30%;
  grid-column-gap: 1rem;
  width: 100%;

  @media (min-width: 501px) and (max-width: 850px) {
    grid-template-columns: 45% 45%;
    grid-template-rows: auto 25vh;
    grid-row-gap: 1rem;
  }

  @media (max-width: 500px) {
    grid-template-columns: 80%;
    grid-template-rows: auto auto 25vh;
    grid-row-gap: 1rem;
    justify-content: center;
  }
}

.header {
  text-align: center;
  grid-column: span 3;

  @media (min-width: 501px) and (max-width: 850px) {
    grid-column: span 2;
  }

  @media (max-width: 500px) {
    grid-column: span 1;
  }
}
