.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 25vh 20%;
  height: calc(25vh + 20%);

  @media (min-width: 501px) and (max-width: 850px) {
    grid-column: span 2;
  }

  @media (max-width: 500px) {
    grid-column: auto;
  }
}

.chatBox {
  overflow-y: auto;
  display: grid;
  grid-auto-rows: min-content;
  padding: 0.3rem;
  border: 1px solid #ccc;
  border-bottom: none;
  max-height: 25vh;

  div {
    display: grid;
    grid-template-columns: auto auto 3fr;
    grid-column-gap: 0.5rem;
  }
}

.systemMessage {
  color: red;
  font-weight: bold;
}

.otherMessage {
  color: navy;
  font-weight: bold;
}

.time {
  font-weight: lighter;
}

.textInput {
  border: 1px solid #ccc;
}
