.wrapper {
  grid-column: span 3;
}

.bigButton {
  width: 75%;
  padding: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  display: block;
  margin: 2rem auto;
  max-width: 20rem;
  color: white;
  background-color: black;
  transition: all 0.25s ease;
  &:hover,
  &:active,
  &:focus {
    color: black;
    background-color: white;
  }
}

.input {
  display: block;
  margin: 1rem;
}
